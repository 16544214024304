import styled from "styled-components";
import bgText from '../static/img/banner_font.png';

export const TextBox = styled.div`
width: 100%;
height: 100px;
position:absolute;
z-index:3;
margin: 130px auto;
line-height: 100px;
text-align: center;
font-weight: 800;
font-size: 32px;
background-image: url(${bgText});
-webkit-text-fill-color: #3360;
-webkit-background-clip: text;
background-size: 300px 100px;
animation: masked-animation 20s infinite linear;

@keyframes masked-animation {
    0% {
        background-position: 0 0;
    }
    to {
        background-position: 300px 0
    }
}
`

export const Main = styled.div`
    width:100%;
    height:450px;
    min-height:100px;
    position:fixed;
    top:0;
    left:0;
    z-index:100;
    overflow:hidden;
    ::after{
        content:'';
        position:absolute;
        left:0;
        top:0;
        width:100%;
        height:100%;
        background-color:rgba(0,0,0,0.3);
    }
`

export const BannerBox = styled.div`
    width:100%;
    height:450px;
    background-size:cover;
    background-position:conter conter
`
export const Context = styled.div`
    width:100%;
    height:100%;
    position:absolute;
    left:0;
    top:0;
    display:flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;
    z-index:200;
    transition:all .5s;

    span{
        color:#fff;
        font-size:40px;
        font-weight:bold;
        transition:all .5s;
        text-shadow:0 1px 2px rgba(0, 0, 0, 0.4);
    }

    img{
        transition:all .5s
    }

`