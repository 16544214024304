import React from 'react'
import './footer.css'

const Footer = () => {
    return (
        <div className="web_footer">
            <div className="web_footer_content">
                <a href="https://beian.miit.gov.cn/" target="_blank">备案号：粤ICP备19024686号</a>
            </div>
        </div>
    )
}

export default Footer