import React, { Component } from 'react';
import { Loading3QuartersOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { message, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

class Recommend extends Component {

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            send: false,
            page: 1,
            length: 5,
            sendNo: false,
            copyList: [],
            pageEnd: 0,
            messInfo: true
        }
    }

    render() {
        let list = this.state.list;
        list = list.filter(item => item !== undefined)
        return (
            <div className='recommend_box'>
                <div className='recomend_data'>
                    <div className='recomtitle'>
                        <span>推荐列表</span>
                        <span className='recomtitle_right_icon' onClick={this.handleDataSend}>
                            {
                                this.state.send ? <LoadingOutlined /> : <Loading3QuartersOutlined />
                            }
                        </span>
                    </div>
                    <div className='recommend_list'>
                        {
                            list.map(item => {
                                return (
                                    <Link to={'/detail/' + item.id} key={item.id}>
                                        <div className='recommend_item'>
                                            <img className='recom_item_pic' src={item.user.logo} alt='1' />
                                            <div className='recom_item_font'>
                                                <Tooltip title={item.title}>
                                                    <h3>{item.title}</h3>
                                                </Tooltip>
                                                <Tooltip title={item.describe}>
                                                    <p className='recom_describe'>{item.describe}</p>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        )
    }

    componentDidMount = () => {
        this.handleSendRecommendData()
    }
    //没有数据的时候提示信息
    handleMessNot = () => {
        message.info('没有更多数据了！');
        this.setState({
            messInfo: false
        })
    }
    //请求推荐数据
    handleSendRecommendData = () => {
        !this.state.sendNo && axios.post('/api/getArticle', {
            page: 1,
            length: 10,
            hot: 1
        }).then(res => {
            if (res.data.ecode === 0) {
                this.setState({
                    list: res.data.data.list,
                },() => {
                    setTimeout(()=>{
                        this.setState({
                            send: false
                        })
                    },1000)
                })
            }
        }).catch(err => {
            console.log('请求失败：', err);
        })
    }
    //点击加载更多
    handleDataSend = () => {
        if (this.state.sendNo) {

            this.setState({
                pageEnd: this.state.page
            })

            this.handlePageReset()
            return
        }
        this.setState({
            send: true,
            page: this.state.page + 1
        }, () => {
            this.handleSendRecommendData()
        })
    }

    //当没有数据了使用本地数据
    handleDatamessage = () => {
        let { page, length, copyList } = this.state;
        let list = [];
        for (let i = (page - 1) * length; i < page * length; i++) {
            list.push(copyList[i]);
        }
        setTimeout(() => {
            this.setState({
                list,
                send: false,
                page: this.state.page + 1
            })
        }, 1300)
    }

    handlePageReset = () => {
        if (this.state.page >= this.state.pageEnd) {
            this.setState({
                page: 1,
                send: true
            }, () => {
                this.handleDatamessage()
            })
        } else {
            this.setState({
                send: true
            }, () => {
                this.handleDatamessage()
            })
        }
    }

}
export default Recommend;