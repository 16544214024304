import React, { Component, Fragment } from 'react'
import { Carousel } from 'antd';
import { BannerBox, Main, Context } from './styled'

import Logo from '../static/img/index_03.png'
import Axios from 'axios';

class Header extends Component {
    constructor() {
        super()
        this.state = {
            minHeight: false,
            ad: []
        }
    }
    render() {
        return (
            <Fragment>
                <Main id="header">
                    <Carousel effect="fade" autoplay dots={false}>
                        {
                            this.state.ad.map(item => {
                                return <div key={item.img}>
                                    <BannerBox style={{ backgroundImage: "url(" + item.img + ")" }}>
                                    </BannerBox>
                                </div>
                            })
                        }
                    </Carousel>
                    <Context id="content">
                        <img src={Logo} alt="成长笔记本"></img>
                        <span>记录你成长的点点滴滴</span>
                    </Context>
                </Main>
                <div style={{ height: '500px' }}></div>
            </Fragment>
        )
    }
    componentDidMount() {
        this.handleGetAdList()
        window.addEventListener('scroll', () => {
            const height = 450 - document.documentElement.scrollTop;
            const content = document.querySelector('#content')

            let { minHeight } = this.state;
            document.querySelector('#header').style.height = height + 'px'
            if (height > 100) {
                content.style.transform = ''
                this.setState({
                    minHeight: false
                })
            }
            if (height <= 100 && !minHeight) {
                content.style.transform = 'translateY(40px)'
                this.setState({
                    minHeight: true
                })
            }
        })
    }
    // 获取轮播图照片
    handleGetAdList = () => {
        Axios.post('/api/getAd').then(res => {
            this.setState({
                ad: res.data.data
            })
        }).catch(err => {
            console.log(err);
        })
    }
}

export default Header