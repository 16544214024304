import React, { Component, Fragment } from 'react';
import './index.css';
import Item from './item';
import axios from 'axios';
import { Skeleton } from 'antd';
// import WebLoa from '../../component/webloading';
import Recommend from './recommend';
import Header from '../../header/'
import Footer from '../../component/footer/footer.js'

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            page: 1,
            length: 10,
            send: true,
            loading: true,
            sendState: false,
            webloading: true
        }
    }
    render() {
        return (
            <Fragment>
                <Header />
                {
                    // this.state.webloading && this.state.page === 1 ? <WebLoa data={this.state.loading} /> : ''
                }
                {
                    (
                        <div className='home_box'>
                            <div className='home_detail'>
                                <div className='home_list'>
                                    {
                                        this.state.list.map(item => {
                                            return <Item key={item.id} data={item} />
                                        })
                                    }
                                    {
                                        this.state.loading && <Skeleton active width='630' />
                                    }
                                </div>
                                {
                                    this.state.sendState && <div className='notG'>没有更多啦~~~</div>
                                }
                            </div>
                            <div className='introduce'><Recommend data={this.state.list} /></div>
                        </div>
                    )
                }
                {
                    !this.state.loading && <Footer />
                }
            </Fragment>
        )
    }

    componentDidMount() {
        this.handleDataChange();
        window.addEventListener('scroll', () => {
            this.handleScrollChange();

            const top = document.documentElement.scrollTop
            const hot = document.querySelector('.introduce')
            if (!hot) return
            if (top > 360) {
                hot.style.position = 'fixed'
                hot.style.top = '120px'
                hot.style.right = (document.documentElement.offsetWidth - 1200) / 2 + 'px'
            } else {
                hot.style.position = ''
            }
        })
    }

    //获取博客列表数据
    handleDataChange = () => {
        this.state.send && axios.post('/api/getArticle', {
            page: this.state.page,
            length: this.state.length,
            hot: 0
        }).then((res) => {
            let list = res.data.data.list;
            let _this = this;

            if (res.data.ecode === -1 || res.data.data.list.length === 0) {
                this.setState({
                    sendState: true,
                    send: false,
                    loading: false
                })
                return;
            }
            this.setState({
                list: [...this.state.list, ...list],
                send: false,
                loading: false
            }, () => {
                setTimeout(() => {
                    _this.setState({
                        webloading: false
                    })
                }, 2000)
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    //触底加载
    handleScrollChange = () => {
        let _this = this;
        let scrollTop = document.documentElement.scrollTop;
        let windowHeight = document.documentElement.offsetHeight;
        let scrollHeight = document.documentElement.scrollHeight;
        if (scrollTop + windowHeight === scrollHeight) {
            let page = _this.state.page
            if (_this.state.send || this.state.sendState) return;
            _this.setState({
                page: page + 1,
                send: true,
                loading: true
            }, () => {
                _this.handleDataChange();
            })
        }
    }

}

export default Home;