import styled from 'styled-components'

export const Banner = styled.div`
    height:300px;
    width:100%;
    .banner_item{
        width:100%;
        height:100%;
        background-size:cover;
        background-repeat:no-repeat;
        background-position:center center;
    }
`