import React, { Component } from 'react'
import { Carousel, Tag } from 'antd';
import { Banner } from './style';
import './index.css'
import axios from 'axios'

class AuthorContent extends Component {

    constructor() {
        super()
        this.state = {
            bannerList: []
        }
    }

    render() {
        return (
            <div className="author_main">
                <div className="author_header">
                    <Carousel autoplay>
                        {
                            this.state.bannerList.map(item => {
                                return (
                                    <Banner key={item.img}>
                                        <div className="banner_item" style={{ backgroundImage: `url(${item.img})` }}></div>
                                    </Banner>
                                )
                            })
                        }
                    </Carousel>
                </div>
                <div className="author_content">
                    <div className="author_logo">
                        <div className="author_logo_pic">
                            <img src="https://images.pexels.com/photos/4677668/pexels-photo-4677668.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="用户名" />
                        </div>
                        <span className="author_tip">个性签名~~</span>
                    </div>
                    <div className="author_focus">
                        <span className="author_focus_key">他的标签：</span>

                        <Tag color="red">设计</Tag>
                        <Tag color="#ccc">策划</Tag>
                        <Tag color="magenta">电商</Tag>

                    </div>
                </div>
            </div>
        )
    }

    componentDidMount() {
        this.handleGetBannerList()
    }

    handleGetBannerList() {
        axios.post('/api/getAd').then(res => {
            this.setState({
                bannerList: res.data.data
            })
        }).catch(err => {
            console.log(err);
        })
    }

}

export default AuthorContent