import React, { Component, Fragment } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Detail, Home, AuthorContent } from './page';
import { BackTop } from 'antd';

class App extends Component {
    render() {
        return (
            <Fragment>
                <BrowserRouter>
                    <BackTop />
                    <Route path='/' exact component={Home} />
                    <Route path='/detail' component={Detail} />
                    <Route path="/author" component={AuthorContent} />
                </BrowserRouter>
            </Fragment>
        )
    }
}

export default App;