import React from 'react';
import { Link } from 'react-router-dom';

const Item = (props) => {
    return(
        <Link to={'/detail/'+props.data.id}>
            <div className='item_box'>
                <div className='font_icon'>
                    <div className='item_font'>
                        <h3>{props.data.title}</h3>
                        {/* <p dangerouslySetInnerHTML={{__html:props.data.context}}></p> */}
                        <p>{props.data.describe}</p>
                    </div>
                    <div className='item_icon'>
                        <img src={props.data.logo} alt={props.data.title} />
                    </div>
                </div>
                {/* <div className='item_foot'>
                    <span className='item_author'>{props.data.author}</span>
                    <span>
                        <span className="iconfont">&#xe600;</span>
                        {props.data.comment}
                    </span>
                    <span>
                        <span className="iconfont">&#xe604;</span>
                        {props.data.give}
                    </span>
                </div> */}
                <div className="user">
                    <div className="user_logo">
                        <img src={props.data.user.logo} alt={props.data.user.nickname} />
                    </div>
                    <div className="user_name">
                        <span>{props.data.user.nickname}</span>
                    </div>
                    <div className="user_name">
                        <span>{props.data.ctime}</span>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default Item;