import React, { Component, Fragment } from 'react';
import axios from 'axios';
import './index.css';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { Spin } from 'antd';
import Header from '../../header/'
import Footer from '../../component/footer/footer.js'
import ReactMarkdown from 'react-markdown'
import CodeBlock from '../../component/CodeBlock';
import 'braft-editor/dist/output.css'

class Detail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            did: window.location.href.split('detail/')[1],
            send: true,
            data: [],
            loading: true
        }
    }
    render() {

        return (
            <Fragment>
                <Header />
                <div className='detail_box'>
                    {
                        this.state.loading && (
                            <div className='detail_loading'>
                                <Spin size="large" />
                            </div>
                        )
                    }
                    {
                        this.state.ecode !== -1 ? (
                            <Fragment>
                                <div className='detail_title'>
                                    <h3>{this.state.data.title}</h3>
                                    <div className="article_author_context">
                                        {
                                            this.state.data.author && <img className='grow_blog' alt='sumu' src={this.state.data.author.logo} />
                                        }
                                        {
                                            this.state.data.author && <span>{this.state.data.author.nickname}</span>
                                        }
                                        <span>{this.state.data.ctime}</span>
                                    </div>
                                </div>
                                {
                                    this.state.data.ctype === 0 ? 
                                    <div className='detail_content braft-output-content' dangerouslySetInnerHTML={{ __html: this.state.data.context }}></div> : 
                                    <ReactMarkdown 
                                        className="detail_content"
                                        escapeHtml={false} 
                                        source={this.state.data.context}
                                        renderers={{
                                            code:CodeBlock
                                        }}
                                    />
                                }
                            </Fragment>
                        ) : (
                                <div className='not_fount'>
                                    <Result
                                        status="404"
                                        title="404"
                                        subTitle="Sorry, the page you visited does not exist."
                                        extra={<Link to={'/'}><Button type="primary">Back Home</Button></Link>}
                                    />
                                </div>
                            )
                    }
                </div>
                {
                    !this.state.loading && <Footer />
                }
            </Fragment>
        )
    }

    componentDidMount() {
        this.state.send && axios.get('/api/getArticleDetail?id=' + this.state.did).then(res => {
            if (res.data.ecode === 0) {
                this.setState({
                    data: res.data.data,
                    loading: false
                })
            } else {
                this.setState({
                    ecode: -1,
                    loading: false
                })
            }
        }).catch(err => {
            console.log(err);
        })
    }
}

export default Detail;